import React, { useEffect, useState } from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import TextImageBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/TextImageBlock"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"

import queryString from "query-string"
import { useAuth0 } from "@auth0/auth0-react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/sharp-light-svg-icons"

import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Figure from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/Figure"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import TextReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/TextReveal"

const LoginPage = () => {
  const { loginWithRedirect, isLoading } = useAuth0()
  const [redirectUri, setRedirectUri] = useState("")

  useEffect(() => {
    if (window && window.location && window.location.search) {
      setRedirectUri(
        queryString.parse(window.location.search).redirect || "/de-de"
      )
    }
  }, [])

  return (
    <Layout language="de">
      <section className="pb-10 login_info-block">
        <div className="block-w-container">
          <Headline
            headline="Das neue Login-System der ROTHENBERGER-Website"
            headlineLevel="h1"
          />
          <BlockWrapper block={{}}>
            <div className="flex flex-row w-full pb-8 mb-4">
              {/* <HeadlineEnhanced
                  kicker={block.kicker}
                  headline={block.headline}
                  headlineLevel={block.headline_level}
                  subheadline={block.subheadline}
                  className="pt-8 lg:pt-0"
                /> */}
              <div className="w-full md:w-1/2">
                <Richtext
                  text={`Im Rahmen der technischen Aktualisierung unserer Website haben wir unser Login-System umgestellt. Das neue System stellt dabei höhere Anforderungen an die Sicherheit und erfordert somit stärkere Passworte.
`}
                />
              </div>
              <div className="w-full mt-8 text-center md:w-1/2">
                <button
                  onClick={() =>
                    loginWithRedirect({
                      appState: { targetUrl: redirectUri },
                    })
                  }
                  className="inline-block btn btn-primary"
                >
                  <FontAwesomeIcon
                    icon={faUser}
                    size="1x"
                    className="inline-block -mt-0.5 align-middle"
                  />{" "}
                  Weiter zum Login-System
                </button>
              </div>
            </div>

            <div className="flex flex-row flex-wrap -mx-grid">
              <div
                className={`relative w-full lg:w-1/2 px-grid flex flex-col p-10`}
              >
                <div className="flex-1">
                  <Headline
                    headline="Sie haben bereits einen Account?"
                    headlineLevel="h3"
                  />
                  <p className="pb-4 prose max-w-none">
                    Falls Sie bereits einen ROTHENBERGER-Account haben,
                    verwenden Sie bitte die Passwort-vergessen-Funktion und
                    vergeben Sie damit Ihr neues Passwort.
                  </p>
                </div>
                <Figure
                  image={{
                    filename:
                      "https://a.storyblok.com/f/125728/482x644/dbd3698fe3/ro-auth0-login-pwd-hint.png",
                  }}
                  className={`md:w-full mr-2`}
                />
              </div>
              <div
                className={`relative w-full lg:w-1/2 px-grid flex flex-col p-10`}
              >
                <div className="flex-1">
                  <Headline
                    headline="Sie sind neu auf der ROTHENBERGER-Website?"
                    headlineLevel="h3"
                  />
                  <p className="pb-4 prose max-w-none">
                    Nutzen Sie einfach die Registrierungsfunktion auf der
                    folgenden Login-Seite.
                  </p>
                </div>
                <Figure
                  image={{
                    filename:
                      "https://a.storyblok.com/f/125728/482x644/73a927a5a2/ro-auth0-login-reg-hint.png",
                  }}
                  className={`md:w-full mr-2`}
                />
              </div>
            </div>
          </BlockWrapper>
        </div>
      </section>
    </Layout>
  )
}

export default LoginPage
